import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  Grid,
  Stack,
  FormControl,
  IconButton,
  useMediaQuery,
  useTheme
} from "@mui/material";
import dayjs from "dayjs";
import AutoComplete from "../../../components/common/AutoComplete";
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MenuItem from "@mui/material/MenuItem";
import CssTextField from "../../../components/common/CssTextField.jsx";
import StyledDatePicker from "../../../components/common/StyledDatePicker.jsx";
import CssMultiTextField from "../../../components/common/CssMultiTextField.jsx";
import StyledButton from "../../../components/common/StyledButton";
import StyledLoadingButton from "../../../components/common/StyledLoadingButton";
import MainCard from "../../../components/common/MainCard.jsx";
import AmountDialog from "../../../components/common/amountDialog.jsx";
import "./AddExpenseEntry.scss";
import expenseEntryService from "../GraphQL/Services.js";
import { LOAD_EXPENSE_CODE, LOAD_RATES } from "../GraphQL/Queries";
import { literals } from "../../../enums/literalCodes.js";
import ApolloContextProvider from "../../../GraphQL/ApolloContextProvider.js";
import jsUtils from "../../../utils/jsUtils";
import { ExpenseEntrySaveSchema,ExpenseEntryPostSchema } from "../../../validationSchemas";
import { expenseEntryModel } from "../Model/expenseEntryModel";
import { openSnackbar } from '../../../redux-toolkit/slices/snackbar';
import StyledTooltip from "../../../components/common/StyledTooltip";
import { useDispatch } from "react-redux";
import AutoCompleteExpense from "../../../components/common/ExpenseAutoComplete";

const INITIALSTATE = {
  clientMatter: "",
  entryDate: dayjs(new Date()).format("YYYY-MM-DD"),
  date: dayjs(new Date()),
  activityId: 66000,
  id: -1,
  ts: null,
  modifiedById: 2,
  modifiedDate: null,
  invoiceId: null,
  description: "",
  note: "",
  budgetPhaseId: null,
  employeeId: null,
  quantity: 0,
  amount: parseFloat(0).toFixed(2),
  rate: parseFloat(0).toFixed(2),
  expenseCodeId: "",
  doNotCharge: false,
  geoLocationId: null,
  writeOff: false,
  customerId: undefined,
  billable: null,
  activityTypeId: "",
  activityDate: "2023-07-28",
  isAdjusted: false,
  adjustedDate: "2023-08-02",
  activityType: "Expense",
  status : "Draft",
  taxExempt: null
};

const AddExpenseEntry = ({ isOpenFromModel, selectedExpenseEntry, handleClose, removeWidgetFromDashboard, widgetId }) => {
  const [state, setState] = useState(isOpenFromModel ? selectedExpenseEntry : INITIALSTATE);
  const [errors, setErrors] = useState({});
  const [shouldFetch, setShouldFetch] = useState(true);

  const { data } = useQuery(LOAD_EXPENSE_CODE);
  const [allExpenseCodes, setAllExpenseCodes] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [allActivityTypes, setAllActivityTypes] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [isSaving, setisSaving] = useState(false);
  const [isPosting, setisPosting] = useState(false);
  const [openDatePicker, setopenDatePicker] = useState(false);
  const [isPostorSave, setisPostorSave] = useState(null);

  const dispatch = useDispatch();

  const [doNotCharge, setDoNotCharge] = useState(false);
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const { data: ratesData } = useQuery(LOAD_RATES, {
    skip: shouldFetch,
    variables: { id: Number(state.expenseCodeId) }
  });
  const [selectedOptionTimekeeper, setSelectedOptionTimekeeper] = useState(null);
  const [selectedOptionClientMatter, setSelectedOptionClientMatter] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedExpenseCode, setSelectedExpenseCode] = useState(null);
  const OptionDescription = (option) => (option?.code ? option.code + " - " : "") + (option.description || "");
  const timeKeeperDescription = (option) => option.description || "";
  const customerIdAndDraft = state?.customerId !== null && state?.status === literals.DRAFT;

  const handleDate = (date) => {
    //Converting date to the YYYY-MM-DD format as required by the API.
    setState({
      ...state,
      entryDate: date !== null && date.format("YYYY-MM-DD"),
      date: date,
    });
    setErrors({ ...errors, date: "" });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    //if rate is being changed, update amount too.
    if(name === 'rate'){
        setState({
          ...state,
          rate: parseInt(value),
          amount: parseFloat(value * state.quantity).toFixed(2),
        });
    }
    else{
    setState({ ...state, [name]: value });
    }

    // Clear the corresponding error state when the input value changes
    setErrors({ ...errors, [name]: null });

  }

  const handleActivityType = (event) => {
    const { name, value } = event.target;
    if (event.target.value == 103) {
      setState({
        ...state,
        [name]: value
      });
      setIsDisabled(false)
    }
    else {
    const newRate = ratesData.getRateByExpenseCode;
      setState({
        ...state,
        [name]: value,
        rate: newRate,
        amount: parseFloat(newRate * state.quantity).toFixed(2)
      });
      setIsDisabled(true)
    }
  }

  const resetForm = () => {
    setState({
      ...INITIALSTATE,
      activityTypeId: data.getAllActivityType[0].id
    });
    setSelectedOptionTimekeeper(null)
    setSelectedOptionClientMatter(null)
    setSelectedExpenseCode(null);
    setSelectedLocation(null)
  }

  const handleDatePicker = () => {
    setopenDatePicker(!openDatePicker);
  }

  useEffect(() => {
    if (data) {
      setAllExpenseCodes(data.getAllExpenseCodes)
      setAllLocations(data.getAllGeoLocations)
      setAllActivityTypes(data.getAllActivityType)
      setState({
        ...state,
        activityTypeId: data.getAllActivityType[0].id
      })
      if (data.getAllExpenseCodes[0].id) {
        setShouldFetch(false)
      }
    }
  }, [data]);

  useEffect(() => {
    if (ratesData) {
    const newRate = ratesData.getRateByExpenseCode;
      setState({
        ...state,
        rate: newRate,
        amount: parseFloat(newRate * state.quantity).toFixed(2)
      })
    }
  }, [ratesData])

  const onDiscardModal = () => {
    if (isOpenFromModel) {
      handleClose()
    } else {
      removeWidgetFromDashboard(widgetId)
    }
  }

  const handleQuantityChange = (event) => {
    let updatedQuantity = event.target.value;
    if (event.target.value < 1) updatedQuantity = INITIALSTATE.quantity;
    setState({
      ...state,
      quantity: parseInt(updatedQuantity),
      amount: parseFloat(state.rate * updatedQuantity).toFixed(2),
    });
    setErrors({ ...errors, [event.target.name]: null });
  }

  const GetSecondaryComponents = () => {
    return (
      <div className="secondarytimer">
        <div>
          <Tooltip>
            <IconButton onClick={() => onDiscardModal()}>
              <CloseIcon style={{ color: '#0050C3' }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    )
  }

  const handleDiscount = (value) => {
    let updatedNote = "";

    if (state.note?.length > 0) {
      const availableLength = 2000 - value.length;
      if (state?.note?.length < availableLength) {
        if (state?.note?.includes("Courtesy Discount:")) {
          let lineBreakedSplittedText = state?.note?.split(/^/gm);

          let indexTORemove = lineBreakedSplittedText.findIndex((item) =>
            item.includes("Courtesy Discount:")
          );

          lineBreakedSplittedText.splice(indexTORemove, 1);

          lineBreakedSplittedText.push(
            indexTORemove !== lineBreakedSplittedText.length
              ? "\u000A" + value
              : value
          );

          updatedNote = lineBreakedSplittedText.toString().replaceAll(",", "");
        } else {
          updatedNote = state.note + "\u000A" + value;
        }
        setState({ ...state, note: updatedNote });
      }
      else {
        dispatch(
          openSnackbar({
            message: literals.MAXIMUM_CHARACTERS_EXCEEDED,
            severity: literals.ERROR,
          })
        );
      }
    } else {
      updatedNote = value;
      setState({ ...state, note: updatedNote });

    }

  };

  const handleDoNotCharge = () => {
    if (!doNotCharge) {
      if (state?.note?.length < 1986) {
        setState({
          ...state,
          note:
            state.note.length === 0
              ? state.note.concat("DO NOT CHARGE")
              : state.note.concat("\nDO NOT CHARGE"),
        });
        setDoNotCharge(!doNotCharge);

      } else {
        dispatch(
          openSnackbar({
            message: literals.MAXIMUM_CHARACTERS_EXCEEDED,
            severity: literals.ERROR,
          })
        );
      }
    } else {
      setState({
        ...state,
        note: state.note.replace("DO NOT CHARGE", "").trim(),
      });
      setDoNotCharge(!doNotCharge);
    }

  };

  const setOptionsChanges = (options, field) => {
    if (options) {
      switch (field) {
        case "customerId":{
          let { matterId, clientId, enforceBillableFlag, billableType } = options;
          let { billable, billableCode } = jsUtils.getBillableInfo(billableType);

          setState((prevState) => ({
            ...prevState,
            customerId: parseInt(matterId),
            clientId: parseInt(clientId),
            enforceBillableFlag,
            billable,
            billableCode,
            status: literals.DRAFT
          }));
          break;
        }

        case "employeeId":{
          let employeeId = parseInt(options.id);
          let taxExempt = options.employeeEntity.taxExempt ? "Yes" : "No"

          setState((prevState) => ({
            ...prevState,
            employeeId: employeeId,
            taxExempt: taxExempt
          }));
          break;
        }

        case "geoLocationId":{
          let geoLocationId = parseInt(options.id);

          setState((prevState) => ({
            ...prevState,
            geoLocationId
          }));
          break;
        }

        case "expenseCodeId":{
          let expenseCodeId = parseInt(options.id);

          setState((prevState) => ({
            ...prevState,
            expenseCodeId
          }));
          break;
        }
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: null,
      }));
    } else {
      setState((prevState) => {
        const newState = {
          ...prevState,
          [field]: null,
          taxExempt: ""
        };
      
        if (field === "customerId") {
          newState.billable = 0;
          newState.billableCode = "";
          newState.status = "";
        }
      
        return newState;
      });
    }

    if (field === "customerId") {
      setSelectedOptionTimekeeper("")
    }

  };

  const onDataSubmit = async () => {
    //Mapping the state to the model that is required for the request.      
    var ObjectModel = jsUtils.mapObjectToModel(expenseEntryModel, state);
    ObjectModel.rate = Number(ObjectModel.rate)
    ObjectModel.amount = Number(ObjectModel.amount)
    ObjectModel.activityTypeId = Number(ObjectModel.activityTypeId)
    ObjectModel.quantity = Number(ObjectModel.quantity)
    ObjectModel.expenseCodeId = Number(ObjectModel.expenseCodeId)
    ObjectModel.geoLocationId = Number(ObjectModel.geoLocationId)

    let serviceFunction;
    
    if (isPostorSave === literals.SAVE) {
      serviceFunction = expenseEntryService.saveExpenseEntry;
      setisSaving(true)
    }
    else { 
      serviceFunction = expenseEntryService.postExpenseEntry;
      setisPosting(true)
    }

    try {
      const { data } = await serviceFunction(ObjectModel);
      setisSaving(false);
      setisPosting(false);
      handleResponse(getResponse(data));
    } catch (e) {
      handleCatch(e);
    }
  };

  const getResponse = (data) => {

    if (isPostorSave === literals.SAVE) {
      return data.saveActivityExpenseDraft
    }
    else { return data.postExpense }
  };

  const getInputAdornment = (field) => {

    if (errors[field]) {
      return (
        <InputAdornment position="end">
          <StyledTooltip
            title={errors[field].message}
            severity={errors[field].errorLevel}
            placement="left"
            arrow
          />
        </InputAdornment>
      )
    }
  };

  const createSnackbar = (disable, successMessage, errorMessage) => {
    dispatch(
      openSnackbar({
        message: !disable ? successMessage : errorMessage,
        severity: !disable ? literals.SUCCESS : literals.ERROR,
      })
    );
  };

  const ResetErrorsAndFields = (disabled) => {
    if (!disabled) {
      setErrors({});
      resetForm();
    }
  };

  const handleResponse = (response) => {

    const { disablePost, disableSave, complianceMessages } = response;

    const successMessageSave = literals.EXPENSE_ENTRY_SAVED;
    const errorMessageSave = literals.EXPENSE_ENTRY_CANT_SAVED;
    const successMessagePost = literals.EXPENSE_ENTRY_POSTED;
    const errorMessagePost = literals.EXPENSE_ENTRY_CANT_POSTED;

    if (complianceMessages.length > 0) {
      const accumulatedErrors = {};
      complianceMessages.forEach((error) => {
        accumulatedErrors[jsUtils.getExpenseFieldName(error.field)] =
        {
          message: error.message,
          errorLevel: error.errorLevel,
        };
      });
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...accumulatedErrors,
      }));
    }

    if (isPostorSave === literals.SAVE) {
      createSnackbar(disableSave, successMessageSave, errorMessageSave);
      ResetErrorsAndFields(disableSave);
    } else {
      createSnackbar(disablePost, successMessagePost, errorMessagePost);
      ResetErrorsAndFields(disablePost);
    }
    
    setisPostorSave(null);

  };

  const handleCatch = (e) => {
    dispatch(
      openSnackbar({
        message: literals.SOMETHING_WENT_WRONG,
        severity: literals.ERROR,
      })
    );
    setisSaving(false);
    setisPosting(false);
    setisPostorSave(null);
    console.error(e);
  };

  const onSubmit = (action) => {

    //Two different schemas are defined for Save  and Post actions
    var schema = action === literals.SAVE ? ExpenseEntrySaveSchema : ExpenseEntryPostSchema;

    schema
      .validate(state, { abortEarly: false })
      .then(() => {
        setisPostorSave(action);
      })
      .catch((err) => {
        // if state is invalid, update Errors state with error messages
        const newErrors = {};
        if (err && err.inner) {
          err.inner.forEach((error) => {
            newErrors[error.path] = {
              message: error.message,
              errorLevel: "DisablePost",
            };
          });
        }
        setisPostorSave(null);
        setErrors(newErrors);
      });
  };

  useEffect(()=>{
    if(isPostorSave !== null){onDataSubmit();}
  },[isPostorSave])

  const getCalendarIcon = (error) => {
    return (
      <>
        <Tooltip title="Open Calendar">
          <DateRangeIcon onClick={() => handleDatePicker()} />
        </Tooltip>
        {!!error && (
          <StyledTooltip title={error.message} severity={error.errorLevel} placement="left" arrow />
        )}
      </>
    );
  };

  return (
    <ApolloContextProvider uri='/expense-management/graphql'>
      <form>
        <MainCard
          className="time-entry-main-card"
          title={literals.EXPENSE_ENTRY}
          secondary={GetSecondaryComponents()}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <AutoComplete
                setOptionsChanges={setOptionsChanges}
                state={state}
                errors={errors}
                selectedOption={selectedOptionClientMatter}
                setSelectedOption={setSelectedOptionClientMatter}
                searchSuggestions={expenseEntryService.searchClientMatters}
                getOptionLabel={OptionDescription}
                field={"customerId"}
                label={"Client/Matter"}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} >
              <CssTextField
                label="Type"
                fullWidth
                color={jsUtils.getFieldSeverity(errors.date)}
                InputLabelProps={{ shrink: true }}
                size="small"
                name="activityTypeId"
                value={state.activityTypeId}
                select
                onChange={handleActivityType}
                error={!!errors.activityTypeId}
                InputProps={{
                  endAdornment: (getInputAdornment("activityTypeId"))
                }}
              >
                {allActivityTypes?.map((data, index) => {
                  return (
                    <MenuItem key={index} value={data.id}>
                      {data.code}
                    </MenuItem>
                  );
                })}
              </CssTextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StyledDatePicker
                    label="Date"
                    open={openDatePicker}
                    onClose={() => handleDatePicker()}
                    slotProps={{
                      textField: {
                        required: true,
                        error: !!errors.date,
                        InputLabelProps: { shrink: true },
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="end">
                              {getCalendarIcon(errors.date)}
                            </InputAdornment>
                          ),
                        }
                      },
                    }}
                    value={state.date}
                    onChange={(date) => handleDate(date)}
                    name="entryDate"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    inputFormat="MM/DD/YYYY"
                    color={jsUtils.getFieldSeverity(errors.date)}
                    format="MM/DD/YYYY"
                    className="custom-datepicker"
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <AutoComplete
                setOptionsChanges={setOptionsChanges}
                state={state}
                searchId={state.customerId}
                errors={errors}
                selectedOption={selectedOptionTimekeeper}
                setSelectedOption={setSelectedOptionTimekeeper}
                searchSuggestions={expenseEntryService.getTimeKeepers}
                getOptionLabel={timeKeeperDescription}
                field={"employeeId"}
                label={"Timekeeper"}
                disabled={!customerIdAndDraft}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <AutoCompleteExpense
                options={allLocations}
                state={state}
                errors={errors}
                getOptionLabel={OptionDescription}
                field={"geoLocationId"}
                label={"Location"}
                value={state.geoLocationId}
                selectedOption={selectedLocation}
                setSelectedOption={setSelectedLocation}
                setOptionsChanges={setOptionsChanges}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <AutoCompleteExpense
                  options={allExpenseCodes}
                  state={state}
                  errors={errors}
                  getOptionLabel={OptionDescription}
                  field={"expenseCodeId"}
                  label={"Expense"}
                  value={state.expenseCodeId}
                  selectedOption={selectedExpenseCode}
                  setSelectedOption={setSelectedExpenseCode}
                  setOptionsChanges={setOptionsChanges}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
              <CssTextField
                label="Quantity"
                fullWidth
                InputLabelProps={{ shrink: true }}
                color={jsUtils.getFieldSeverity(errors.quantity)}
                size="small"
                autoComplete="off"
                name="quantity"
                value={state.quantity}
                onChange={(quantity) => handleQuantityChange(quantity)}
                error={!!errors.quantity}
                InputProps={{
                  endAdornment: (getInputAdornment("quantity"))
                }}
              // helperText={errors.hours}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
              <CssTextField
                label="Rate"
                fullWidth
                color={jsUtils.getFieldSeverity(errors.rate)}
                name="rate"
                autoComplete="off"
                disabled={isDisabled}
                value={state.rate}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                  endAdornment: (getInputAdornment("rate")),
                  sx: {
                    background: "red"
                  },
                }}
                error={!!errors.rate}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
              <CssTextField
                label="Amount"
                fullWidth
                color={jsUtils.getFieldSeverity(errors.amount)}
                name="amount"
                autoComplete="off"
                disabled={true}
                value={state.amount}
                InputLabelProps={{ shrink: true }}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                  endAdornment: (getInputAdornment("amount")),
                  sx: {
                    background: "red"
                  },
                }}
                error={!!errors.amount}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} x1={12}>
              <CssMultiTextField
                label="Narrative"
                fullWidth
                color={jsUtils.getFieldSeverity(errors.description)}
                name="description"
                autoComplete="off"
                value={state.description}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                multiline
                rows={2}
                InputProps={{
                  endAdornment: (getInputAdornment("description"))
                }}
                error={!!errors.description}
                style={{ height: "80px" }}
                helperText={`Character count: ${state.description.length} Word count: ${state.description.split(' ').filter(word => word !== '').length}`}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9.5} x1={9.5}>
              <CssMultiTextField
                label="Notes"
                fullWidth
                color={jsUtils.getFieldSeverity(errors.note)}
                InputLabelProps={{ shrink: true }}
                name="note"
                size="small"
                autoComplete="off"
                onChange={handleChange}
                value={state.note}
                multiline
                rows={2}
                InputProps={{
                  endAdornment: (getInputAdornment("note"))
                }}
                error={!!errors.note}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2.5} xl={2.5} container>
              <StyledButton
                variant="outlined"
                style={{
                  color: "#0050C3",
                  marginTop: "0px",
                  width: "100%",
                  height: "31px",
                  fontSize: "13px",
                }}
                onClick={() => setOpenDialog(!openDialog)}
              // disabled={state?.status == literals.DRAFT ? false : true}
              >
                Discount
              </StyledButton>
              <StyledButton
                variant="outlined"
                onClick={handleDoNotCharge}
                style={{
                  color: "#0050C3",
                  marginTop: "0px",
                  width: "100%",
                  height: "31px",
                  fontWeight: doNotCharge ? "bold" : "500",
                  fontSize: "13px",
                  marginLeft: isMediumScreen ? "10px" : "0",
                  letterSpacing: "0",
                }}
              // disabled={state?.status == literals.DRAFT ? false : true}
              >
                Do Not Charge
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
              <CssTextField
                label="Billable"
                fullWidth
                color={jsUtils.getFieldSeverity(errors.billable)}
                name="billable"
                value={state.billableCode}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                size="small"
                error={!!errors.billable}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <CssTextField
                label="Transaction Date"
                fullWidth
                color={jsUtils.getFieldSeverity(errors.transactionDate)}
                value={state.transactionDate}
                name="transactionDate"
                disabled={true}
                InputLabelProps={{ shrink: true }}
                size="small"
                error={!!errors.transactionDate}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
              <CssTextField
                label="Period Date"
                fullWidth
                color={jsUtils.getFieldSeverity(errors.periodDate)}
                name="periodDate"
                value="2023-03 Open"
                disabled={true}
                InputLabelProps={{ shrink: true }}
                size="small"
                error={!!errors.periodDate}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
              <CssTextField
                label="Tax Exempt"
                fullWidth
                color={jsUtils.getFieldSeverity(errors.tax)}
                name="tax"
                value={state.taxExempt}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                size="small"
                error={!!errors.tax}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
              <CssTextField
                label="Status"
                fullWidth
                color={jsUtils.getFieldSeverity(errors.status)}
                name="status"
                value={state?.customerId != null ? state?.status : ""}
                disabled={true}
                InputLabelProps={{ shrink: true }}
                size="small"
                error={!!errors.status}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} x1={12}>
              <div className="view-more-button">
                <Stack>
                  {
                    // if there is some entry selected than remove cancel button
                    // !isOpenFromModel &&
                    <StyledButton
                      onClick={resetForm}
                    >
                      {literals.CLEAR_ENTRY}
                    </StyledButton>
                  }
                </Stack>
                {/* <Stack>
                  {
                    // if there is some entry selected than remove cancel button
                    // !isOpenFromModel &&
                    <StyledButton
                      // onClick={() => handleModalClose()}
                      variant="outlined"
                      startIcon={<UploadFileIcon />}
                    >
                      //  {literals.CLEAR_ENTRY} 
                      Upload Attachement
                    </StyledButton>
                  }
                </Stack> */}
                <Stack>
                  <StyledLoadingButton
                    onClick={() => onSubmit(literals.SAVE)}
                    loading={isSaving}
                    variant="outlined"
                  >
                    {literals.SAVE}
                  </StyledLoadingButton>
                  <StyledLoadingButton
                    loading={isPosting}
                    onClick={() => onSubmit(literals.POST)}
                    variant="contained"
                  >
                    {literals.POST}
                  </StyledLoadingButton>
                </Stack>
              </div>
            </Grid>
          </Grid>
        </MainCard>
      </form>
      <AmountDialog
        open={openDialog}
        closeModel={() => setOpenDialog(false)}
        handleSubmit={handleDiscount}
      />
    </ApolloContextProvider>
  );
};

export default AddExpenseEntry;
