import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  matterId: undefined,
  ltbDeadlineUrl: '',
  selectedOptionClientMatter: null,
};

const ltbInputSlice = createSlice({
  name: 'ltbInput',
  initialState,
  reducers: {
    setLtbInputState: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setLtbInputState } = ltbInputSlice.actions;
export default ltbInputSlice.reducer;
