import React from "react";
import "./FeeCalendar.scss";
import LinearProgress from '@mui/joy/LinearProgress';
import FeeCalendarUtils from "./utils";
import { SkeletonProgressBar,  SkeletonText} from "./Skeleton";

const rowGap = {
    display: 'flex', 
    justifyContent: 'space-between',
    paddingTop: 5,
    fontSize : "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.14px",
}

const inLine={display: 'flex'}

function SummaryComponent({summary, calView, selectedDate}){
    return (
        <div style={{paddingTop: '15px'}}>
            {/* Heading */}
            <div style={{
                textAlign: 'center', fontWeight: 500, fontSize: '14px', lineHeight: '20px', letterSpacing: "0.14px"
            }}>{FeeCalendarUtils.returnSummaryHeading(calView, selectedDate)}</div>

            {/* Summary */}
            <div style={{rowGap: 10, paddingTop: 5, color: '#000'}}>
                <div style={rowGap}>
                    <div style={inLine}>
                        Number of Time Entries: {FeeCalendarUtils.summaryIsLoaded(summary, 'noOfEntries')  ?
                        summary.noOfEntries.toFixed(2) :
                        <SkeletonText/>
                        }
                    </div>
                    <div style={inLine}>
                        Admin Hours: {FeeCalendarUtils.summaryIsLoaded(summary, 'adminHours') ?
                         summary.adminHours.toFixed(2) :
                         <SkeletonText/>
                        }
                    </div>
                </div>

                <div style={rowGap}>
                    <div style={inLine}>
                        Billable Hours: {FeeCalendarUtils.summaryIsLoaded(summary, 'billable') ?
                         summary.billable.toFixed(2) :
                         <SkeletonText/>
                        }
                    </div>
                    <div style={inLine}>
                        Total Hours: {FeeCalendarUtils.summaryIsLoaded(summary, 'totalHours') ?
                         summary.totalHours.toFixed(2) :
                         <SkeletonText/>
                        }
                    </div>
                </div>

                <div style={rowGap}>
                    <div style={inLine}>
                        Non-Billable Hours: {FeeCalendarUtils.summaryIsLoaded(summary, 'nonBillable') ?
                         summary.nonBillable.toFixed(2) :
                         <SkeletonText/>
                        }
                    </div>
                    <div style={inLine}>
                        Billable Amount: {FeeCalendarUtils.summaryIsLoaded(summary, 'billableAmount') ?
                         (summary.billableAmount ? `$${summary.billableAmount.toFixed(2)}` : "N/A") :
                         <SkeletonText/>
                        }
                    </div>
                </div>
            
            </div>
    </div>
    )
}

export default function SummarySection(props) {

    let {summary,calView, selectedDate} = props;
    const noSummaryData = Object.values(summary).every(o => o === null || o == 0);
    
    const monthTotalHours = FeeCalendarUtils.summaryIsLoaded(summary, 'totalHours') && !noSummaryData ? parseInt(summary?.totalHours + 30) : 0;
    const percentage = FeeCalendarUtils.percentage(summary?.totalHours, monthTotalHours) || 0;

    return (
        <div style={{padding: 9, backgroundColor: '#F6FAFD', marginTop: 52}}>
            {
                FeeCalendarUtils.summaryIsLoaded(summary, 'totalHours') ? <div>
                    {/* Progress bar */}
                    <div style={{display:'flex', gap: 8, paddingTop:10, paddingLeft: 10}}>
                        <LinearProgress determinate value={parseFloat(percentage).toFixed(2)} 
                        variant="solid"
                        sx={{
                            '--LinearProgress-thickness': '11px',
                            '--LinearProgress-progressThickness': '13px',
                        }}/>
                        <div style={{color: '#00000099', paddingLeft: 4, fontSize: '14px'}}>{parseFloat(percentage).toFixed(2)}%</div>
                    </div>
                    <div style={{textAlign: 'center', paddingTop: 5}}>
                        {parseFloat(summary?.totalHours).toFixed(2)} out of {monthTotalHours} hours entered this month
                    </div>
                </div> : 
                <SkeletonProgressBar />
            }

            <SummaryComponent summary={summary} calView={calView} selectedDate={selectedDate} />
        </div>
    )

}