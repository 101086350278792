import { GridActionsCellItem } from '@mui/x-data-grid';
import timeEntryService from '../../../services/timeEntryService';
import { Tooltip } from '@mui/material';
import { DateRangeIcon } from '@mui/x-date-pickers';
import { literals } from '../../../enums/literalCodes';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import {
  getColumnEditCellComponent,
  getColumnRenderComponent,
} from './ColumnRenderCell';

// add utils file with the action change on the columns
export const getColumns = (
  columnsDef,
  columnWidths,
  handleAddtoHours,
  getRowsValues,
  setOptionsChanges,
  errors,
  setSelectedOption,
  OptionDescription,
  handleCellValueChange,
  OptionTimekeeperDescription,
  entryTypeList,
  handleDate,
  handleHours,
  handleQuantity,
  handleAmountEdit,
  handleCancelRowModal,
  handleonSubmit,
  handleEditClick,
  handleDeleteClick,
  timersActive,
  onTimerClicked,
  getSuggestionsFunction=()=>{}
) => {
  const getColumnWidth = (field, defaultWidth) => {
    return columnWidths[field] || defaultWidth;
  };

  const getTimerWidth = (_timersActive) => {
    let isActiveTimer = _timersActive?.length;
    if (columnWidths['time']) {
      return columnWidths['time'];
    }

    return isActiveTimer ? 300 : 160;
  };

  const checkIfDisabled = (params, field) => {
    switch (field) {
      case 'hours':
        return (
          params.row.istracker ||
          params.row.status === literals.POSTED
        );
      case 'quantity':
        return (
          params.row.type !== 'Task Fee' ||
          params.row.istracker ||
          params.row.status === literals.POSTED
        );
      case 'amount':
        return (
          params.row.type !== 'Simple Fee' ||
          params.row.istracker ||
          params.row.status === literals.POSTED
        );
      case 'description':
        let statusList = [
          literals.POSTED,
          literals.DRAFT,
          literals.TAGGED,
        ];
        return !statusList.includes(params.row.status);
      default:
        return params.row.status === literals.POSTED;
    }
  };

  const getColumnValue = (params, field) => {
    switch (field) {
      case 'date':
        return params.row.activityDate;
      case 'clientMattersDescription':
      case 'matterName':
      case 'timekeeper':
      case 'type':
      case 'taskCode':
      case 'activity':
      case 'location':
      case 'description':
        return getRowsValues(params, field);
      case 'time':
        return '';
      default:
        return params?.row?.[field];
    }
  };

  const getCalendarIcon = () => {
    return (
      <Tooltip title="Open Calendar">
        <DateRangeIcon className="MuiIcons" />
      </Tooltip>
    );
  };

  const getEmptyDefaultValue = (field) => {
    switch (field) {
      case 'clientMattersDescription':
        return {
          billableType: { id: '1', code: 'Billable' },
          clientCode: '',
          clientDesc: '',
          clientId: '',
          description: '',
          enforceBillableFlag: false,
        };
      default:
        return {
          id: '',
          code: '',
          description: '',
        };
    }
  };

  const actionHandler = (field, value, params) => {
    switch (field) {
      case 'time':
        handleAddtoHours(value?.id, value?.newTime);
        return;
      case 'clientMattersDescription':
      case 'matterName':
      case 'timekeeper':
      case 'taskCode':
      case 'activity':
      case 'location':
        if (!value) {
          value = getEmptyDefaultValue(field);
        }
        setOptionsChanges(value, field, params);
        return;
      case 'type':
        handleCellValueChange(value, field, params);
        return;
      case 'date':
        handleDate(params.row, value);
        return;
      case 'hours':
        handleHours(value, params);
        return;
      case 'quantity':
        handleQuantity(value, params);
        return;
      case 'amount':
        handleAmountEdit(value, params);
        return;
      case 'description':
        handleCellValueChange(value, field, params);
        return;
    }
  };

  const renderColumnComponent = (params) => {
    let column = columnsDef.find(
      (item) => item.field === params.field
    );
    const isDisabled = checkIfDisabled(params, column.field);
    const val = getColumnValue(params, column.field);
    let ColumnComponent = isDisabled ? (
      <div className="MuiDataGrid-cellContent">{val}</div>
    ) : (
      getColumnRenderComponent(
        params,
        column,
        actionHandler,
        val,
        onTimerClicked
      )
    );
    return ColumnComponent;
  };

  const renderEditColumnComponent = (params) => {
    let column = columnsDef.find(
      (item) => item.field === params.field
    );
    const isDisabled = checkIfDisabled(params, column.field);
    const val = getColumnValue(params, column.field);
    params['description']=val || params?.formattedValue;
    let ColumnComponent = getColumnEditCellComponent(
      params,
      column,
      getCalendarIcon,
      val,
      setOptionsChanges,
      errors,
      setSelectedOption,
      OptionDescription,
      entryTypeList,
      actionHandler,
      getSuggestionsFunction,
      onTimerClicked,
      OptionTimekeeperDescription
    );
    return ColumnComponent;
  };

  let updatedColumns = columnsDef.map((column) => {
    if (column.field !== 'actions') {
      if (column.updateRenderCell) {
        column.renderCell = renderColumnComponent;
      }
      if (column.updateRenderEditCell) {
        column.renderEditCell = renderEditColumnComponent;
      }
    } else {
      column.renderCell = (params) => {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditIcon className="MuiIcons" />
              </Tooltip>
            }
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params?.row)}
            color="inherit"
            key="Edit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Delete">
                <DeleteIcon className="MuiIcons" />
              </Tooltip>
            }
            label="Delete"
            onClick={() =>
              handleDeleteClick([
                params?.row?.activityId || params?.row?.id,
              ])
            }
            color="inherit"
            key="Delete"
          />,
        ];
      };
      column.renderEditCell = (params) => {
        return [
          <GridActionsCellItem
            key="cancel"
            icon={
              <Tooltip title="Cancel">
                <ClearIcon className="MuiIcons" />
              </Tooltip>
            }
            label="Cancel"
            onClick={handleCancelRowModal}
            disabled={params?.row?.istracker}
          />,
          <GridActionsCellItem
            key="Save"
            icon={
              <Tooltip title="Save">
                <SaveIcon className="MuiIcons" />
              </Tooltip>
            }
            label="Save"
            onClick={handleonSubmit(
              params?.id,
              literals.SAVE,
              params?.row
            )}
            disabled={params?.row?.istracker}
          />,
          <GridActionsCellItem
            key="Post"
            icon={
              <Tooltip title="Post">
                <AccessAlarmIcon className="MuiIcons" />
              </Tooltip>
            }
            label="Post"
            onClick={handleonSubmit(
              params?.id,
              literals.POST,
              params?.row
            )}
            disabled={params?.row?.istracker}
          />,
        ];
      };
    }

    column.width =
      column.field === 'time'
        ? getTimerWidth(timersActive)
        : getColumnWidth(column.field, column.defaultWidth);
    return column;
  });
  return updatedColumns;
};
