import "./FeeCalendar.scss"

import { GET_TIME_ENTRIES_BY_DATE, POST_TIME_ENTRIES } from "../graphql/mutations/feeCalendar";
import { Grid, IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from '@apollo/client';

import AddIcon from '@mui/icons-material/Add';
import AlertDialog from '../../../components/common/AlertDialog';
import ApolloContextProvider from "../../../GraphQL/ApolloContextProvider";
import { Calendar } from '@fullcalendar/core';
import CloseIcon from "@mui/icons-material/Close";
import Entries from "./TimeEntries";
import FeeCalendarUtils from "./utils";
import { GET_TIME_ENTRIES_BY_DATE_RANGE } from "../graphql/queries/feeCalendar";
import MainCard from "../../../components/common/MainCard";
import SettingsIcon from '@mui/icons-material/Settings';
import StyledLoadingButton from "../../../components/common/StyledLoadingButton";
import SummarySection from "./SummarySection";
import TimeEntryModal from "../TimeEntryGrid/TimeEntryModal"
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import jsUtils from "../../../utils/jsUtils";
import { literals } from "../../../enums/literalCodes";
import { loggedInUser } from "../../../redux-toolkit/slices/userSlice.js";
import multiMonthPlugin from '@fullcalendar/multimonth';
import { openSnackbar } from '../../../redux-toolkit/slices/snackbar';

const InitialSummary = {
  adminHours: null,
  billable: null,
  billableAmount: null,
  noOfEntries: null,
  nonBillable: null,
  totalHours: null
}

export default function FeeCalendar({ removeWidgetFromDashboard, widgetId }) {

  const [dateRange, setDateRange] = useState({})
  const calendarRef = useRef(null);
  const eventSourceRef = useRef([]);
  const [viewType, setViewType] = useState("grouping") // grouping = client/matter view, list = list of time entries for month/day
  const [entries, setEntriesData] = useState([])
  const [dailyEntries, setDailyEntriesData] = useState([])
  const [monthSummary, setMonthSummary] = useState(InitialSummary);
  const [yearlySummary, setYearlySummary] = useState({});
  const [yearlyEntries, setYearlyEntries] = useState([]);
  const [dailySummary, setDailySummary] = useState(InitialSummary);
  const [isPostSelectedMode, setIsPostSelectedMode] = useState(false);
  const selectedDateRef = useRef('');
  const prevSelectedDayElRef = useRef(null);
  const calendarViewRef = useRef({type: 'month', isMonthOrday: 'month'}); // month or year types
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 

  //creating id by Date.now() as we did in the Dashboard widgets for save/post first time.
  const [activityId, setActivityID] = useState(null);
  const [activityDate, setActivityDate] = useState(null);
  const [isOpenFromcalender, setIsOpenFromcalender] = useState(true);
  const [isOpenFromModel, setIsOpenFromModel] = useState(false);
  const [Modal, setModal] = useState(null);

  // Maintain a reference to the previously selected day element
  const { employeeId } = useSelector((state) => state.userState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!employeeId) {
      dispatch(loggedInUser());
    }
  }, []);

  const [getTimeEntriesByDate, { data: dateData }] = useMutation(GET_TIME_ENTRIES_BY_DATE, {
    variables: {
      timeEntrySearchRequest: {
        date: selectedDateRef.current,
        toDate: selectedDateRef.current,
        timekeeperId: Number(localStorage.getItem('empId') || employeeId)
      }
    }
  });

  const [postAll, { data: postData, loading : isPosting, error : postError }] = useMutation(POST_TIME_ENTRIES);
  const [postSelected, postSelectedState] = useMutation(POST_TIME_ENTRIES);

  // YEAR/MONTH DATA ENTRIES
  const { data, refetch, error } = useQuery(GET_TIME_ENTRIES_BY_DATE_RANGE, {
      variables: {
        feeCalendarRequest: {...dateRange, employeeId: Number(localStorage.getItem('empId') || employeeId)}
      }
    });

  useEffect(() => {
    refetch();
  }, [dateRange])

  useEffect(() => {

    if (error) {
      if (error.graphQLErrors) {
        if (error.graphQLErrors.find(e => e.extensions.code === "access.denied")) {
          dispatch(
            openSnackbar({
              message: literals.ACCESS_DENIED_ERROR,
              severity: literals.ERROR,
            })
          );
        }
      }
    } else if (data) {
      const {feeCalDays, summary, timeEntriesByCM} = data.getFeeCalendarDetails;
      const { events } = FeeCalendarUtils.getCalendarEvents(feeCalDays, 'entryDate');
      updateEvents(events);

      const { entries } = FeeCalendarUtils.getEntriesByRange(timeEntriesByCM);
      const summaryObject =  {
        noOfEntries: summary?.count || 0,
        billable: summary?.billableHours  || 0,
        nonBillable: summary?.nonBillableHours || 0,
        totalHours: summary?.totalHours || 0,
        billableAmount: summary?.totalAmount || 0,
        adminHours: 0
      }

      if (calendarViewRef.current.type == 'month') {
        setEntriesData(entries);
        setMonthSummary(summaryObject);
      }
      else if (calendarViewRef.current.type == 'year') {
        setYearlyEntries(entries)
        setYearlySummary(summaryObject);
      }
      setIsLoading(false);
    }
  }, [data, error])

  useEffect(() => {
    //Fetching selected Day Data...
    if (dateData) {
      const { summary } = FeeCalendarUtils.getSummary(dateData.search.content);
      setDailySummary(summary);
      const { entries } = FeeCalendarUtils.getMonthEntries(dateData.search.content);
      setDailyEntriesData(entries)
      setIsLoading(false);
    }
  }, [dateData])


  const updateEvents = (newEvents) => {
    eventSourceRef.current = newEvents;
    const calendar = calendarRef.current;
    if (calendar) {
      calendar.setOption('events', newEvents);
    }
  };

  const handleDateClick = ({ dateStr, dayEl }) => {

    if (isPostSelectedMode) {
      if (dayEl.dataset.dateSelected === 'true') {
        dayEl.dataset.dateSelected = '';
        dayEl.style.backgroundColor = '';
      } else {
        dayEl.dataset.dateSelected = 'true';
        dayEl.style.backgroundColor = '#8CD2FA99';
      }
    } else {
      // Check if there was a previously selected day
      if (prevSelectedDayElRef.current) {
        // Remove background color from the previously selected day
        prevSelectedDayElRef.current.style.backgroundColor = '';
      }

      if (selectedDateRef.current === dateStr) {
        // Date clicked again, remove background color
        dayEl.style.backgroundColor = '';
        selectedDateRef.current = '';

        calendarViewRef.current = { type: calendarViewRef.current.type, isMonthOrday: 'month' };
        setDailySummary({});
        setDailyEntriesData([])

        //resetting states for the time entry modal.
        setActivityDate(null)
      } else {
        // New date clicked, change background color
        dayEl.style.backgroundColor = '#8CD2FA99';
        selectedDateRef.current = dateStr;

        //setting date to show on the field of TE on the time entry modal.
        setActivityDate(dateStr)
        //When there is no event on the selected day, show the Month view.
        if (FeeCalendarUtils.hasNoEvent(dayEl.textContent)) {
          calendarViewRef.current = { type: calendarViewRef.current.type, isMonthOrday: 'month' }
        }
        else {
          setIsLoading(true);
          calendarViewRef.current = { type: calendarViewRef.current.type, isMonthOrday: 'day' }
          setDailySummary({});
          getTimeEntriesByDate();
          setViewType("grouping");
        }
      }

      prevSelectedDayElRef.current = dayEl;
    }
  };

  const handleEventClick = (info) => {
    const dateStr = info.event.startStr;

    // Find the day element associated with the event
    const dayElements = document.querySelectorAll('.fc-day');

    dayElements.forEach((dayEl) => {
      const dateAttribute = dayEl.getAttribute('data-date');
      if (dateAttribute === dateStr) {
        // Call the handleDateClick function with the day element
        handleDateClick({ dateStr, dayEl});
      }
    });
  };

  const findAllSelectedDays = () => {
    const selectedDays = document.querySelectorAll('[data-date-selected="true"]');
    return selectedDays;
  }

  const clearAllSelectedDays = () => {
    const selectedDays = findAllSelectedDays();
    selectedDays.forEach(day => {
      day.removeAttribute('data-date-selected');
      day.style.backgroundColor = '';
    });
  }

  const handleClickCancelPostSelected = () => {
    setIsPostSelectedMode(false);
    clearAllSelectedDays();
  }

  const handlePostSelectedClick = () => {
    if (isPostSelectedMode) {
      onPostAll();
    } else {
      if (prevSelectedDayElRef.current) {
        prevSelectedDayElRef.current.style.backgroundColor = ''
        prevSelectedDayElRef.current = null;
      }
      if (selectedDateRef.current) {
        selectedDateRef.current = null;
      }
      calendarViewRef.current = { type: calendarViewRef.current.type, isMonthOrday: 'month' };
      setDailySummary({});
      setDailyEntriesData([])

      setActivityDate(null)
      setIsPostSelectedMode(true);
    }
  }

  const resetStates = () => {
    setMonthSummary(InitialSummary);
    setYearlySummary(InitialSummary);
    setDailySummary(InitialSummary);
    setEntriesData([]);
    setYearlyEntries([]);
    setDailyEntriesData([]);
    setIsLoading(true);
    setViewType("grouping");
  }

  useEffect(() => {
    const calendarEl = calendarRef.current;
    const calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin , multiMonthPlugin, interactionPlugin],
      headerToolbar: {
        left: 'prev title next',
        center: '',
        right: 'dayGridMonth,multiMonthYear'
      },
      eventOrder: true,
      events: eventSourceRef.current || [],
      dayHeaderFormat: {
        weekday: 'short'
      },
      multiMonthMaxColumns: 2,
      showNonCurrentDates: false,        
      datesSet: function (info) {
        calendar.removeAllEvents(); // Clear existing events
        resetStates();

        // Find all month title elements and attach a click event listener
        const monthTitles = calendarEl.querySelectorAll('.fc-multimonth-title');
        const yearTitle = calendarEl.querySelector('.fc-toolbar-title');

        monthTitles.forEach((monthTitle) => {
          monthTitle.addEventListener('click', () => {
            const clickedMonth = monthTitle.textContent.trim(); // Trim whitespace
            const currentYear = yearTitle.textContent.trim();

            // Convert month name to month number
            const monthNumber = new Date(`${clickedMonth} 1, 2000`).getMonth() + 1;

            // Navigate to the clicked month view
            calendar.gotoDate(`${currentYear}-${monthNumber.toString().padStart(2, '0')}`);
            calendar.changeView('dayGridMonth');
          });
        });

        const {view } = info;
        const {type} = view;
       
        const currentDate = calendar.getDate();
        setDateRange(prev => {
          if( type === 'multiMonthYear'){
            calendarViewRef.current ={type: 'year', isMonthOrday: 'month'};
            return jsUtils.getYearRange((currentDate.getMonth() + 1), currentDate.getFullYear())
          }
          else{ 
            calendarViewRef.current ={type: 'month', isMonthOrday: 'month'};
            return jsUtils.getMonthRange((currentDate.getMonth() + 1), currentDate.getFullYear())
          }
        })
      },
      eventClick: handleEventClick,
      dateClick: handleDateClick,
    });

    calendar.render();
    calendarRef.current = calendar;

    return () => {
      calendar.destroy();
    };

  }, []);

  // This is necessary for calendar to pickup changes to event handlers based on "post selected" mode state
  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.setOption('eventClick', handleEventClick);
      calendarRef.current.setOption('dateClick', handleDateClick);
    }
  }, [ handleEventClick, handleDateClick ])

  const AddNewTimeEntry = () => {
    setActivityID(null);
    setIsOpenFromcalender(true);
    setIsOpenFromModel(false);
    setOpen(true);
  }

  const disablePostAll = () => {
    if((yearlyEntries.length > 0 || entries.length > 0 || dailyEntries.length > 0 ) && !isPosting){
      return false;
    }
    return true;
  }

  const GetSecondaryComponents = () => {
    return (
      <div className="secondaryItems">
        <div style={{ marginRight: '16px' }}>
          {isPostSelectedMode ? <><Tooltip title="Cancel">
            <StyledLoadingButton
              onClick={() => handleClickCancelPostSelected()}
              style={{ marginRight: '8px'}}
            >{"Cancel"}</StyledLoadingButton>
          </Tooltip>
            <Tooltip title={literals.POST_SELECTED}>
              <StyledLoadingButton
                variant="contained"
                style={{ backgroundColor: "#0050C3" }}
                onClick={() => handlePostSelectedClick()}
              >{"Confirm"}</StyledLoadingButton>
            </Tooltip></> :
            <Tooltip title={literals.POST_SELECTED}>
              <StyledLoadingButton
                variant="contained"
                style={{ backgroundColor: "#0050C3" }}
                onClick={() => handlePostSelectedClick()}
              >{literals.POST_SELECTED}</StyledLoadingButton>
            </Tooltip>}
        </div>
        <Tooltip title={'Post All'}>
          <StyledLoadingButton disabled={disablePostAll()} onClick={() => !disablePostAll() && onPostAll()} id={literals.POST_ALL} variant="contained" style={{ backgroundColor: "#0050C3" }} >{literals.POST_ALL}</StyledLoadingButton>
        </Tooltip>
        <Tooltip title={'Add Time Entry'}>
          <IconButton onClick={() => AddNewTimeEntry()}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={'Settings'}>
          <IconButton>
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={literals.REMOVE_WIDGET}>
          <IconButton onClick={() => removeWidgetFromDashboard(widgetId)}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
        {Modal !== null && <AlertDialog {...Modal} />}
      </div>
    );
  };

  const handleEditModalClose = () => {
    
    //Closing the time entry Modal
    setOpen(false);

    //Resetting the state.
    setActivityID(null);
    setIsOpenFromcalender(true);
    setIsOpenFromModel(false);
    
    //Recalling the APIs
    getTimeEntriesByDate()
    refetch();
   };

  const handleTimeEntry = (activityID) => {
    setActivityID(activityID);
    setIsOpenFromcalender(false);
    setIsOpenFromModel(true);
    setOpen(true);
  };

  const returnSummary = () => {
    if(calendarViewRef.current.type == 'month' && calendarViewRef.current.isMonthOrday == 'month'){
      return monthSummary;
    }
    else if(calendarViewRef.current.type == 'year' && calendarViewRef.current.isMonthOrday == 'month'){
      return yearlySummary;
    }
    else{
      return dailySummary;
    }
  }

  const returnDataEntries = () => {
    if(calendarViewRef.current.type == 'month' && calendarViewRef.current.isMonthOrday == 'month'){
      return entries;
    }
    else if(calendarViewRef.current.type == 'year' && calendarViewRef.current.isMonthOrday == 'month'){
      return yearlyEntries;
    }
    else{
      return dailyEntries;
    }
  }

  const postAllChanges = async () => {
    setModal(null);
    try {
      await postAll({ variables: { req: getPostAllPayload() } });
    } catch (err) {
      console.error('Error:', err);
    }
  };

  const postSelectedChanges = async () => {
    const empId = Number(localStorage.getItem('empId') || employeeId);
    const selectedDayEls = findAllSelectedDays();
    const dateStrs = Array.from(selectedDayEls).map(dayEl => dayEl.dataset.date);

    setModal(null);

    try {
      const requests = dateStrs.map(dateStr => {
        const reqData = { employeeId: empId, fromDate: dateStr, toDate: dateStr };
        return postSelected({ variables: { req: reqData } });
      });
      const responses = await Promise.all(requests);

      let totalEntries = 0;
      let postedEntries = 0;

      responses.forEach(res => {
        const results = res.data.postAllCalendarEntries.results;
        totalEntries += results.length;
        postedEntries += (results.filter(r => r.timeEntryDto)).length
      });
      const message = `${totalEntries} Time Entries Found. ${postedEntries} Posted. ${totalEntries - postedEntries} Not Posted.`;
      dispatch(
        openSnackbar({
          message: message,
          severity: literals.SUCCESS,
        })
      );

      clearAllSelectedDays();
      setIsPostSelectedMode(false);
    } catch (err) {
      dispatch(
        openSnackbar({
          message: literals.AN_UNEXPECTED_ERROR_HAS_OCCURRED,
          severity: literals.ERROR,
        })
      );
    }
  };

  const onDiscardModal = () => {
    setModal(null);
  };

  const onPostAll = () => {
    setModal({
      open: true,
      title: isPostSelectedMode ? literals.POST_SELECTED_TIME_ENTRIES_HEADER : literals.POST_ALL_TIME_ENTRIES_HEADER,
      message: isPostSelectedMode ? literals.POST_SELECTED_TIME_ENTRIES_MESSAGE : literals.POST_ALL_TIME_ENTRIES_MESSAGE,
      cancelLabel: '',
      discardLabel: literals.NO,
      confirmLabel: literals.YES,
      onConfirm: isPostSelectedMode ? postSelectedChanges : postAllChanges,
      onDiscard: onDiscardModal,
      onCancel: onDiscardModal,
      ModalType: literals.THREE_ACTIONS,
    });
  }

  useEffect(() => {
    if (postData) {
      const { results } = postData.postAllCalendarEntries;

      if(results){
        const totalEntries = results.length;
        const Posted = results.filter((entries) => entries.timeEntryDto).length;
        const NotPosted = totalEntries - Posted;
    
        const message = `${totalEntries} Time Entries Found. ${Posted} Posted. ${NotPosted} Not Posted.`;
    
        dispatch(
          openSnackbar({
            message: message,
            severity: literals.SUCCESS,
          })
        );
      }
      else{
        dispatch(
          openSnackbar({
            message: literals.SOMETHING_WENT_WRONG,
            severity: literals.ERROR,
          })
        );
      }
    }

  }, [postData]);
  

  const getPostAllPayload = () => {
    const { current: calendarView } = calendarViewRef;
    const { isMonthOrday } = calendarView || {};
    
    const fromDate = isMonthOrday === "day" ? selectedDateRef.current : dateRange.fromDate;
    const toDate = isMonthOrday === "day" ? selectedDateRef.current : dateRange.toDate;
    const empId = Number(localStorage.getItem('empId') || employeeId);
    return { employeeId: empId, fromDate, toDate };
  };

  return (
    <ApolloContextProvider uri="/time-management/graphql">
      <MainCard
        className="fee-calendar-main-card"
        title={literals.MY_CALENDAR}
        secondary={GetSecondaryComponents()}
      >
        <Grid container>
          <Grid xs={8} md={9} item>
            <div ref={calendarRef}></div>
          </Grid>
          <Grid xs={4} md={3} item>
            <SummarySection summary={returnSummary()} calView={calendarViewRef.current} selectedDate={selectedDateRef.current} />
            <Entries 
              data={returnDataEntries()} 
              viewType={viewType} 
              calView={calendarViewRef.current}
              calCurrentView={calendarViewRef}
              setViewType={v => setViewType(v)}
              handleTimeEntry={handleTimeEntry}
              isLoading={isLoading}
              yearlyDateRange= {{...dateRange, employeeId}}
            />
          </Grid>
        </Grid>
      </MainCard>
      
      {open && <TimeEntryModal
        open={open}
        isOpenFromModel={isOpenFromModel}
        isOpenFromcalender={isOpenFromcalender}
        widgetId ={isOpenFromcalender ? Date.now() : null}
        handleClose={handleEditModalClose}
        selectedTimeEntry={activityId}
        activityDate={activityDate}
      />}
    </ApolloContextProvider>
  );
}